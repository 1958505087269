const ScrollBtn = ({ handleScroll, icon }) => (
  <nav aria-label="page scroll navigation">
    <button
      className="mb-8 bg-primary dark:bg-darkPrimary hover:bg-primaryHover dark:hover:bg-darkPrimaryHover opacity-80 p-4 hover:opacity-100 "
      type="button"
      onClick={handleScroll}
    >
      {icon}
    </button>
  </nav>
);

export default ScrollBtn;
