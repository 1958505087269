const Subtitle = ({
  children, style, className, ...props
}) => (
  <h2
    className={`text-xl text-right md:text-justify${className ? ` ${className}` : ''}`}
    style={{
      letterSpacing: '0.11em',
      ...style,
    }}
    {...props}
  >
    {children}
  </h2>
);

export default Subtitle;
