import React, { useReducer, createContext } from 'react';

export const ConfigContext = createContext();

const initialConfig = {
  webpSupport: true,
  colorScheme: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'BROWSER_WEBP_SUPPORT':
      return { ...state, webpSupport: true };
    case 'NO_BROWSER_WEBP_SUPPORT':
      return { ...state, webpSupport: false };
    case 'COLOR_SCHEME_LIGHT':
      document.documentElement.classList.remove('dark');
      return { ...state, colorScheme: 'light' };
    case 'COLOR_SCHEME_DARK':
      document.documentElement.classList.add('dark');
      return { ...state, colorScheme: 'dark' };
    default:
      return state;
  }
};

export const ConfigContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialConfig);

  return (
    <ConfigContext.Provider value={[state, dispatch]}>
      {children}
    </ConfigContext.Provider>
  );
};
