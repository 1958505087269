import Socials from '../components/Socials';
import ScrollBtn from '../components/ScrollBtn';
import Title from '../components/Title';
import Subtitle from '../components/Subtitle';
import { ArrowDownIcon } from '../components/icons';

const Page1 = ({ handleScroll }) => (
  <section className="flex items-center justify-between h-screen" id="page-1">
    <Socials />
    <div className="md:mr-8 flex flex-col justify-between items-end h-full">
      <div className="text-right mt-4 md:mt-12">
        <Title className="mb-2 sm:mb-8 text-secondary dark:text-darkSecondary">Kacper Turon</Title>
        <Subtitle className="text-primary dark:text-darkPrimary">FULL-STACK ENGINEER • LONDON</Subtitle>
      </div>
      <ScrollBtn
        handleScroll={(e) => handleScroll('down', e)}
        icon={<ArrowDownIcon alt="scroll down" />}
      />
    </div>
  </section>
);

export default Page1;
