import { lazy, Suspense } from 'react';
import ScrollBtn from '../components/ScrollBtn';
import { ArrowUpIcon } from '../components/icons';

const DetailsBox = lazy(() => import('../components/DetailsBox'));

const Page2 = ({ handleScroll }) => (
  <section
    className="flex items-center justify-end h-screen"
    id="page-2"
  >
    <div className="md:mr-8 flex flex-col justify-between h-full items-end">
      <div />
      <Suspense fallback={<div>All the interesting details would be here</div>}>
        <DetailsBox />
      </Suspense>
      <ScrollBtn
        handleScroll={(e) => handleScroll('up', e)}
        icon={<ArrowUpIcon alt="scroll up" />}
      />
    </div>
  </section>
);

export default Page2;
