const browserSupportsWEBP = () => new Promise((resolve) => {
  const webp = 'data:image/webp;base64,UklGRkAAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAIAAAAAAFZQOCAYAAAAMAEAnQEqAQABAAFAJiWkAANwAP79NmgA';
  const testImg = new Image();
  testImg.src = webp;
  testImg.onerror = (e) => resolve(false);
  testImg.onload = (e) => resolve(true);
});

const setLocalStorage = (name, value) => {
  localStorage.setItem(name, value);
};

const readLocalStorage = (name) => localStorage.getItem(name);

const removeLocalStorage = (name) => localStorage.removeItem(name);

const getColorScheme = () => {
  const storedVal = readLocalStorage('colorScheme');
  if (storedVal) return storedVal;
  if (window.matchMedia('(prefers-color-scheme: dark)').matches) return 'dark';
  return 'light';
};

export {
  setLocalStorage,
  readLocalStorage,
  removeLocalStorage,
  getColorScheme,
  browserSupportsWEBP,
};
