import { lazy, Suspense, useContext } from 'react';
import { ConfigContext } from '../config-context';

const Border = ({ className }) => <div className={`absolute w-full h-4 bg-border dark:bg-darkBorder z-10 ${className}`} />;

const MaskImg = lazy(() => import('./MaskImg'));
const KacperImg = lazy(() => import('./KacperImg'));

const Background = () => {
  const [config, dispatch] = useContext(ConfigContext);
  return (
    <div className="absolute z-0 w-full h-full top-0" id="background">
      <Border className="top-0" />
      <Suspense fallback={<div>Kacper should soon be here..</div>}>
        <MaskImg webpSupport={config.webpSupport} />
      </Suspense>
      <div className="absolute w-full h-screen bg-texture" />
      <Suspense fallback={<div>Oh no he&apos;s not wearing a mask</div>}>
        <KacperImg webpSupport={config.webpSupport} />
      </Suspense>
      <Suspense fallback={<div>Oh no he&apos;s not wearing a mask</div>}>
        <KacperImg webpSupport={config.webpSupport} className="bottom-0 not-ios-hide" />
      </Suspense>
      <Border className="bottom-0" />
    </div>
  );
};

export default Background;
