import './App.css';
import React, { useContext, useEffect, useState } from 'react';
import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
import Background from './components/Background';
import { ConfigContext } from './config-context';
import {
  browserSupportsWEBP, getColorScheme,
} from './helpers';
import ColorSchemeSwitch from './components/ColorSchemeSwitch';
// import ScrollBar, { useScrollBar } from './components/ScrollBar';

const App = () => {
  const [config, dispatch] = useContext(ConfigContext);
  // const barY = useScrollBar();

  useEffect(() => {
    const checkWEBPBrowserSupport = async () => {
      const webSupport = await browserSupportsWEBP();
      if (!webSupport) dispatch({ type: 'NO_BROWSER_WEBP_SUPPORT' });
    };
    checkWEBPBrowserSupport();
  }, []);

  useEffect(() => {
    const colorScheme = getColorScheme();
    if (colorScheme === 'dark') {
      dispatch({ type: 'COLOR_SCHEME_DARK' });
    } else {
      dispatch({ type: 'COLOR_SCHEME_LIGHT' });
    }
  }, []);

  const handleScroll = (dir) => {
    window.scrollTo({
      top: dir === 'down' ? document.body.scrollHeight : 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="bg-pageBg dark:bg-darkPageBg">
      <div
        className="relative w-screen max-w-screen-2xl h-full mx-auto "
      >
        {/* {barY && <ScrollBar y={barY} x={0} />}
      {barY && <ScrollBar y={barY + 30} x={0} />}
    {barY && <ScrollBar y={barY + 60} x={0} />} */}

        <main className="relative z-10" id="foreground">
          <Page1 handleScroll={handleScroll} />
          <Page2 handleScroll={handleScroll} />
        </main>
        <ColorSchemeSwitch />
        <Background />

      </div>
    </div>
  );
};

export default App;
