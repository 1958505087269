import { useState, useEffect, useContext } from 'react';
import { ConfigContext } from '../config-context';
import { SunIcon, MoonIcon } from './icons';
import { setLocalStorage } from '../helpers';

const ColorSchemeSwitch = () => {
  const [{ colorScheme }, dispatch] = useContext(ConfigContext);

  const handleClick = () => {
    const newColorSchemeVal = colorScheme === 'light' ? 'dark' : 'light';
    if (colorScheme === 'light') dispatch({ type: 'COLOR_SCHEME_DARK' });
    if (colorScheme === 'dark') dispatch({ type: 'COLOR_SCHEME_LIGHT' });
    setLocalStorage('colorScheme', newColorSchemeVal);
  };

  return (
    <>
      {colorScheme === null && null}
      <div className="absolute top-8 left-4 z-20">
        <button type="button" onClick={handleClick}>
          { colorScheme === 'dark' && <SunIcon /> }
          { colorScheme === 'light' && <MoonIcon /> }
        </button>
      </div>
    </>
  );
};

export default ColorSchemeSwitch;
